import { errorToastMessage } from '@/helpers/errorToastMessage';
import { getMonthPeriodDates } from '@/helpers/getPeriodDates';
import Decimal from 'decimal.js-light';
import { getRewardsInfoShared } from '@/api/DashboardInfo';
import { mapGetters } from 'vuex';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

import type { RewardsInfo } from '@/api/schema';
import type { AxiosError } from 'axios';

export interface IRewardsInfoData {
  loading: boolean;
  dates: { from: string; to: string };
  rewardsInfo: Partial<RewardsInfo>;
}

export default {
  components: {
    VExtendedDataPicker
  },
  data(): IRewardsInfoData {
    return {
      loading: false,
      dates: this.getDefaultDates(),
      rewardsInfo: {}
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId'])
  },

  watch: {
    operatorId: {
      handler(newId: number): void {
        if (newId) {
          this.dates = this.getDefaultDates();
        }
      },
      immediate: true
    },

    dates: {
      handler(): void {
        if (this.operatorId) {
          this.getRewardInfo();
        }
      },
      immediate: true
    }
  },

  methods: {
    getDefaultDates(): { from: string; to: string } {
      const [from, to] = getMonthPeriodDates(true);

      return { from, to };
    },

    calculateSum(...args: number[]): string {
      return [...args]
        .reduce((all, curr) => all.plus(new Decimal(curr || 0)), new Decimal(0))
        .toString();
    },

    getRewardInfo(): void {
      this.loading = true;

      getRewardsInfoShared(this.dates.from, this.dates.to)
        .then((data) => {
          this.rewardsInfo = Object.freeze(data);
        })
        .catch((error: AxiosError) => errorToastMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },

    _formatValues(data: unknown): unknown {
      return data;
    },

    formatWithCurrency(
      value: string,
      currency = 'USD',
      format = `0,0`
    ): string {
      return `${this.$options.filters.numeralSpaces(
        value,
        format
      )} ${currency}`;
    },

    formatDataValues<T extends { [key: string]: any }>(
      data: T,
      formatter: <R extends any>([k, v]: [k: string, v: R]) => [
        string,
        string
      ] = this._formatValues
    ): T {
      return Object.fromEntries(Object.entries(data).map(formatter)) as T;
    }
  }
};
