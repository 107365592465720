



















































import type { PropType } from 'vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import DashboardPerformanceTileItem from '@/views/DashboardViews/dashboard-view/DashboardPerfomanceTileItem.vue';

export default {
  name: 'DashboardPerfomanceTile',
  components: { DashboardPerformanceTileItem, Tooltip },
  props: {
    color: {
      type: String as PropType<string>,
      default: (): string => '#696CFF'
    },
    icon: {
      type: String as PropType<string>,
      default: (): string => 'mdi-currency-usd'
    },
    title: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    total: {
      type: [String, Number],
      default: (): string => ''
    },
    withStakes: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    withoutStakes: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    withoutLoyaltyVisit: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    tooltip: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    directionRow: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
};
