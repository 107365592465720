
























































import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import DashboardPerformanceTile from '@/views/DashboardViews/dashboard-view/DashboardPerfomanceTile.vue';
import RewardsInfoMixin from '@/views/DashboardViews/dashboard-view/mixins/RewardsInfoMixin';
import Tooltip from '@/components/tooltip/Tooltip.vue';

interface IUsersData {
  total: string;
  withStakes: string;
  withoutStakes: string;
  withoutLoyaltyVisit?: string;
}

export default {
  name: 'DashboardPerformance',
  components: {
    DashboardPerformanceTile,
    Tooltip
  },

  mixins: [RewardsInfoMixin],

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),

    ngrTooltip(): string {
      return (
        '<div>NGR (users w/o stakes): GGR - Play to Earn Rewards - Promo Rewards</div>' +
        '<div>NGR (users with stakes): GGR - Play to Earn Rewards - Hold to Earn Rewards - Promo Rewards</div>'
      );
    },

    ggrUsersData(): IUsersData {
      return this.formatDataValues({
        total: this.rewardsInfo.totalGgr,
        withStakes: this.rewardsInfo.ggrStakingUsers,
        withoutStakes: this.rewardsInfo.ggrNotStakingUsers
      });
    },

    ngrUsersData(): IUsersData {
      return this.formatDataValues({
        total: this.rewardsInfo.totalNgr,
        withStakes: this.rewardsInfo.ngrStakingUsers,
        withoutStakes: this.rewardsInfo.ngrNotStakingUsers
      });
    },

    usersData(): IUsersData {
      const { totalUsersCount, nonStakeUsersCount, stakeUsersCount } =
        this.rewardsInfo;

      const nonStakePart = this.$options.filters.numeralSpaces(
        nonStakeUsersCount / totalUsersCount,
        '0,[0].[00]%'
      );
      const stakePart = this.$options.filters.numeralSpaces(
        stakeUsersCount / totalUsersCount,
        '0,[0].[00]%'
      );

      return {
        total: String(totalUsersCount),
        withoutStakes: `${nonStakeUsersCount ?? 0}${
          totalUsersCount === 0 ? '' : ` (${nonStakePart})`
        }`,
        withStakes: `${stakeUsersCount ?? 0}${
          totalUsersCount === 0 ? '' : ` (${stakePart})`
        }`
      };
    },
    minDate(): string {
      return DateTime.utc().minus({ year: 1 }).toFormat('yyyy-LL-dd');
    }
  },

  methods: {
    _formatValues([key, value]: [string, string]): [string, string] {
      return [key, this.formatWithCurrency(value || '0')];
    }
  }
};
