



































import Tooltip from '@/components/tooltip/Tooltip.vue';
export default {
  name: 'DashboardPerformanceTileItem',
  components: {
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#fff'
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String || Number,
      default: ''
    }
  }
};
