import { DateTime } from 'luxon';

export function getMonthPeriodDates(
  includeFullLastMonth: boolean = false
): string[] {
  const today: DateTime = DateTime.now();

  if (includeFullLastMonth && today.day === 1) {
    return [
      today.minus({ month: 1 }).startOf('month').toFormat('yyyy-LL-dd'),
      today.minus({ day: 1 }).toFormat('yyyy-LL-dd')
    ];
  } else {
    return [
      today.startOf('month').toFormat('yyyy-LL-dd'),
      today.minus({ day: 1 }).toFormat('yyyy-LL-dd')
    ];
  }
}
